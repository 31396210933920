import { environment } from '../environments/environment';

const ecosystemName = 'bolivariano';
const appName = 'Bolivariano';

export const defaultConfig = {
  clientAppCode: 'agentWeb',
  apiRootPath: '/ws-webagent/',
  signatureKey: 'GIFHESNCHFDJDNDNCCN',
  ...environment.appConfig,
  // Configuración default para ser re-escrita al inicio
  deviceControl: true,
  initialForm: 'welcome',
  deviceVerification: true,
  applicationName: `Agentes ${appName}`,
  companyName: ecosystemName,
  devicePreffix: 'KMGEVNCV17h',
  verificationFrequency: 30000,
  sessionTimeout: 300000,
  warningDuration: 30000,
  assetsBase: `assets/${ecosystemName}`,
  logoutPath: '',
  privatePolicy: 'https://www.bolivariano.com/privacy',
  googleApiKey: 'AIzaSyB9ARkqTDRoIK_1H4EIO8rUCyMeLtWAyG8',
  sessionExpirationTime: 300.0,
  settings: {
    locale: 'en--US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left',
    routerAnimation: 'slideFadeinUp',
    menuColor: 'gray',
    theme: 'default',
    authPagesColor: 'white',
    primaryColor: '#019AA9',
    leftMenuWidth: 284.0,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
  },
};
