<nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
  <nz-slider
    nzIncluded
    nzRange
    [(ngModel)]="value"
    [nzDisabled]="disabled()"
    [nzTipFormatter]="formatter"
    [nzStep]="50"
    [nzMin]="timeMin"
    [nzMax]="timeMax"
    [nzMarks]="marks"
    (nzOnAfterChange)="inputChanged()">
  </nz-slider>
</nz-form-control>
