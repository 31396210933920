import { Component } from '@angular/core';
import { pdfPreview } from "./preview";

const DEFAULT_BODY_MESSAGE = 'Haga click o arrastre su archivo aqui';
const DEFAULT_FILE_TYPES = '*'; //  'application/pdf,image/*'
const DEFAULT_MAX_FILE_SIZE = 5000000;
const ERROR_DETAILS = {
  size: 'El archivo supera el tamaño máximo permitido',
};

@Component({
  selector: 'app-modal-standard',
  template: `
    <div>
      <p>{{ body }}</p>
      <p [innerHtml]="rawBody"></p>
    </div>
  `,
})
export class StandardModalComponent {
  body: String = '';
  rawBody: String = '';

  setContent(params) {
    this.body = params?.body || '';
    this.rawBody = params?.rawBody || '';
  }
}

@Component({
  selector: 'app-modal-upload',
  template: `
    <div class="mb-2" *ngIf="errorMessage">
      <nz-alert nzType="error" [nzMessage]="errorMessage" nzShowIcon></nz-alert>
    </div>
    <div
      ngx-dropzone
      [accept]="accept"
      [multiple]="'false'"
      [maxFileSize]="maxFileSize"
      (change)="onSelect($event)"
      [class]="modalClass"
      *ngIf="!previewBurnedPdf"
    >
      <ngx-dropzone-label>
        <div>
          <p><i nz-icon nzType="cloud-upload"></i></p>
          <p>{{ body }}</p>
        </div>
      </ngx-dropzone-label>
      <ngx-dropzone-image-preview
        ngProjectAs="ngx-dropzone-preview"
        *ngFor="let f of files"
        [file]="f"
        [removable]="true"
        (removed)="onRemove(f)"
      >
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </div>
    <div [class]="modalClass" *ngIf="previewBurnedPdf">
    <img [src]="pdfPreview" class="pdfPreview"/>
    </div>
  `,
  styles: [
    `
      [nz-icon] {
        font-size: 32px;
      }

      .pdfPreview {
        max-width: 140px;
        opacity: .8;
    }
    `,
  ],
})
export class UploadModalComponent {
  callback: any = null;
  errorMessage: String = '';
  body: String = '';
  accept: String;
  multiple = false;
  maxFileSize: String = '';
  files: File[] = [];
  modalClass: string = "";
  previewBurnedPdf: any;
  pdfPreview: any;

  setContent(params) {
    this.callback = params?.callback;
    this.body = params?.body || DEFAULT_BODY_MESSAGE;
    this.accept = params?.accept || DEFAULT_FILE_TYPES;
    this.maxFileSize = params?.maxFileSize || DEFAULT_MAX_FILE_SIZE;
    this.modalClass = params?.modalClass || "";
    if (params?.maxFileSizeErr) ERROR_DETAILS.size = params?.maxFileSizeErr
  }

  onSelect(event) {
    const reason = event?.rejectedFiles[0]?.reason;
    this.errorMessage = reason ? ERROR_DETAILS[reason] : '';
    this.files = [...event.addedFiles];
    if (this.files?.[0]) {
      const fileExt = this.files[0]?.name?.split('.')?.pop()?.toLowerCase() ?? '';
      this.previewBurnedPdf = fileExt === 'pdf' ? true : false;
      this.pdfPreview = pdfPreview;
    }
    // this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  getFiles(callback) {
    const reader = new FileReader();
    if (this.files && this.files.length) {
      const [file] = this.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileContentObj = {
          fileName: file.name,
          fileType: file.type,
          fileContent: reader.result,
        };
        callback(fileContentObj);
      };
      return;
    }
    callback(null);
  }
}
