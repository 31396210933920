import { Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleMap, MapMarker, MapInfoWindow } from '@angular/google-maps';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-map',
  templateUrl: './app-map.component.html',
})
export class AppMapComponent extends FieldComponent {
  apiLoaded: Observable<boolean>;
  latitude: number;
  longitude: number;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  @Input() editable = true;
  @Input() zoom = 16;
  marker: any;
  infoContent = '';

  center: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  override start() {
    super.start();
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: this.latitude ?? position.coords.latitude,
        lng: this.longitude ?? position.coords.longitude,
      };
    });

    this.marker = {
      position: {
        lat: this.latitude ?? this.center.lat,
        lng: this.longitude ?? this.center.lng,
      },
      options: { animation: google.maps.Animation.BOUNCE },
    }
  }

  override updateValue() {
    super.updateValue();
    this.latitude = this.value()?.[0];
    this.longitude = this.value()?.[1];
    this.center = {
      lat: +this.latitude,
      lng: +this.longitude,
    }
    this.marker = {
      position: {
        lat: +this.latitude,
        lng: +this.longitude,
      },
    }
  }

  zoomIn() {
    if (this.zoom < this.mapOptions.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.mapOptions.minZoom) this.zoom--;
  }

  logCenter() { }

  clickOnMap(event) {
    if (!this.editable || !this.enabled()) { return; }
    this.value.set([+event?.latLng?.lat(), +event?.latLng?.lng()]);
    this.latitude = this.value()?.[0];
    this.longitude = this.value()?.[1];
    this.center = {
      lat: +this.latitude,
      lng: +this.longitude,
    }
    this.marker = {
      position: {
        lat: this.latitude ?? this.center.lat,
        lng: this.longitude ?? this.center.lng,
      },
      options: { animation: google.maps.Animation.BOUNCE },
    }
    this.value.set([this.latitude, this.longitude]);
    this.updateObject();
    this.onChangeContent();
  }

  openInfo(marker: MapMarker, content) {
    this.infoContent = content;
    this.infoWindow.open(marker);
  }

  inputChanged2() {
    this.value.set([this.latitude, this.longitude]);
    this.inputChanged();
  }
}
